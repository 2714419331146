import { ApiServiceName } from '../../../config/services';
import { JournalDocumentFilter } from '../../../types/journal';

export class KanbanPatchTicketFilter {
  static readonly type = '[Kanban] Patch ticket filter';
  constructor(
    public params: {
      type: ApiServiceName;
      value: Partial<JournalDocumentFilter>;
    },
  ) {}
}
